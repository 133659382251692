import React from "react";
import { Grid, Stack, Heading, Image, Link } from "@chakra-ui/react";

function SimpleCard({ data }) {
  return (
    <Grid templateColumns={data.data.layout} gap="20px">
      {data.data &&
        data.data.cards.map((card, index) => (
          <Link key={index} to="#">
            <Stack
              shadow="md"
              bg="white"
              overflow="hidden"
              p="3"
              transition="all 0.3s ease-in-out"
              borderRadius="3px"
              borderWidth="2px"
              borderColor="gray.50"
              align="center"
              _hover={{ cursor: "pointer", shadow: "lg" }}
            >
              <Image
                src={card.imageUrl}
                alt={card.label}
                w="auto"
                maxH="120px"
                h="auto"
              />
              <Heading size="sm" fontWeight="normal">
                {card.label}
              </Heading>
            </Stack>
          </Link>
        ))}
    </Grid>
  );
}

export default SimpleCard;
