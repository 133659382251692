import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Center, Circle, HStack, Square, Stack, Text } from "@chakra-ui/layout";
import { GoHome } from "react-icons/go";
import {
  BsChatLeft,
  BsTruck,
  BsShare,
  BsFillSunFill,
  BsMoonFill,
  BsCashCoin,
} from "react-icons/bs";
import { IoExitOutline, IoSettingsOutline } from "react-icons/io5";
import { FaShopify, FaBullhorn } from "react-icons/fa";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import Dashboard from "../dashboard/Dashboard";
import ChatFrame from "../chat/ChatFrame";
import { GoPackage } from "react-icons/go";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPool } from "../../utils/redux/poolSlice";
import { Spinner } from "@chakra-ui/spinner";
import { useSocket } from "../../utils/sockets/useSocket";
import Loading from "../../components/Loading";
import ShopifyFrame from "../shopify/ShopifyFrame";
import ProcurementEntry from "../procurement/ProcurementEntry";

function Entry() {
  const { logout } = useAuth();
  let { pathname } = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();
  const pool = useSelector((state) => state.pool.value);

  console.log("pool", pool);

  const { socket, connected, connect, disconnect } = useSocket();

  useEffect(() => {
    //connect();

    return () => {
      //disconnect();
    };
  }, []);

  const loading = false;

  return (
    <HStack
      h="100vh"
      bg={useColorModeValue("gray.50", "gray.900")}
      justify="start"
      align="start"
      spacing="0px"
    >
      {/* Left Side Bar */}
      <Stack
        w="60px"
        bg={useColorModeValue("gray.800", "gray.900")}
        h="100%"
        shadow="md"
        spacing="1px"
      >
        <Link to="/app">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname === "/app" && "gray.50",
              pathname === "/app" && "blue.900"
            )}
            color={useColorModeValue(
              pathname === "/app" ? "gray.700" : "gray.500",
              pathname === "/app" ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
          >
            <GoHome size="25px" />
          </Square>
        </Link>
        <Link to="/app/chat">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/chat") && "gray.50",
              pathname.startsWith("/app/chat") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/chat") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/chat") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
            position="relative"
          >
            <BsChatLeft size="25px" />
            {loading && (
              <Spinner
                color="red.400"
                bottom="10px"
                right="10px"
                h="15px"
                w="15px"
                position="absolute"
              />
            )}
            {!loading && pool && (
              <Text
                fontSize="10px"
                p="1"
                bg={"red.500"}
                color="white"
                borderRadius="10px"
                position="absolute"
                bottom="10px"
                right="10px"
                shadow="md"
                visibility={
                  pool.unread + pool.incomingChats.length === 0 && "hidden"
                }
              >
                {pool.unread + pool.incomingChats.length}
              </Text>
            )}
          </Square>
        </Link>
        <Link to="/app/shop">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/shop") && "gray.50",
              pathname.startsWith("/app/shop") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/shop") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/shop") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
          >
            <FaShopify size="25px" />
          </Square>
        </Link>
        <Link to="/app/procurement">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/procurement") && "gray.50",
              pathname.startsWith("/app/procurement") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/procurement") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/procurement") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
            position="relative"
          >
            <BsCashCoin size="25px" />
            {loading && (
              <Spinner
                color="red.400"
                bottom="10px"
                right="10px"
                h="15px"
                w="15px"
                position="absolute"
              />
            )}
            {!loading && pool && (
              <Text
                fontSize="10px"
                p="1"
                bg={"red.500"}
                color="white"
                borderRadius="10px"
                position="absolute"
                bottom="10px"
                right="10px"
                shadow="md"
                visibility={
                  pool.unread + pool.incomingChats.length === 0 && "hidden"
                }
              >
                {pool.unread + pool.incomingChats.length}
              </Text>
            )}
          </Square>
        </Link>
        <Link to="/app/fulfillment">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/fulfillment") && "gray.50",
              pathname.startsWith("/app/fulfillment") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/fulfillment") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/fulfillment") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
            position="relative"
          >
            <GoPackage size="25px" />
            {loading && (
              <Spinner
                color="red.400"
                bottom="10px"
                right="10px"
                h="15px"
                w="15px"
                position="absolute"
              />
            )}
            {!loading && pool && (
              <Text
                fontSize="10px"
                p="1"
                bg={"red.500"}
                color="white"
                borderRadius="10px"
                position="absolute"
                bottom="10px"
                right="10px"
                shadow="md"
                visibility={
                  pool.unread + pool.incomingChats.length === 0 && "hidden"
                }
              >
                {pool.unread + pool.incomingChats.length}
              </Text>
            )}
          </Square>
        </Link>
        <Link to="/app/orders">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/orders") && "gray.50",
              pathname.startsWith("/app/orders") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/orders") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/orders") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
          >
            <BsTruck size="25px" />
          </Square>
        </Link>
        <Link to="/app/marketing">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/marketing") && "gray.50",
              pathname.startsWith("/app/marketing") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/marketing") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/marketing") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
          >
            <FaBullhorn size="20px" />
          </Square>
        </Link>
        <Link to="/app/settings">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/app/settings") && "gray.50",
              pathname.startsWith("/app/settings") && "blue.900"
            )}
            color={useColorModeValue(
              pathname.startsWith("/app/settings") ? "gray.700" : "gray.500",
              pathname.startsWith("/app/settings") ? "white" : "gray.500"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "blue.900"),
              color: useColorModeValue("gray.700", "gray.50"),
            }}
          >
            <IoSettingsOutline size="20px" />
          </Square>
        </Link>
        <Square
          size="60px"
          cursor="pointer"
          onClick={toggleColorMode}
          marginTop="auto"
          px="1"
          color={useColorModeValue("gray.500", "gray.500")}
        >
          <HStack
            bg={useColorModeValue("gray.900", "gray.800")}
            w="100%"
            justify={colorMode === "light" ? "end" : "start"}
            borderRadius="40px"
            p="0px"
            transition="all 0.4s ease-in-out"
          >
            {colorMode === "light" ? (
              <Circle bg="gray.700" p="2">
                <BsMoonFill color="white" />
              </Circle>
            ) : (
              <Circle bg="yellow.400" p="2">
                <BsFillSunFill color="black" />
              </Circle>
            )}
          </HStack>
        </Square>
        <Square
          size="60px"
          cursor="pointer"
          onClick={logout}
          marginTop="auto"
          color={useColorModeValue("gray.500", "gray.500")}
          //style={{  }}
          _hover={{
            bg: useColorModeValue("gray.100", "blue.900"),
            color: useColorModeValue("gray.700", "gray.50"),
          }}
        >
          <IoExitOutline size="25px" />
        </Square>
      </Stack>
      {/* Right Side Application */}

      <Routes>
        <Route index element={<Loading />} />
        {socket ? (
          <Route path="/chat/*" element={<ChatFrame />} />
        ) : (
          <Route path="/chat/*" element={<Loading />} />
        )}
        <Route path="/shop/*" element={<ShopifyFrame />} />
        <Route path="/procurement/*" element={<ProcurementEntry />} />
      </Routes>
    </HStack>
  );
}

export default Entry;
