import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiCaretDown, BiCaretRight, BiTrash } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setPageData } from "../../../../utils/redux/pageDataSlice";
import CardGridColumn from "./CardGrid/CardGridColumn";
import ImageAdvertEditor from "./ImageAdvert/ImageAdvertEditor";
import ImageGridCardColumn from "./ImageGridCards/ImageGridCardColumn";
import EditSlider from "./ImageSlider/EditSlider";
import InfoCardsColumn from "./InfoCards/InfoCardsColumn";
import SimpleCardsColumn from "./SimpleCards/SimpleCardsColumn";
import TrackCardEdit from "./TrackCardEdit";
import TrackOrderEditor from "./TrackOrderEditor";

function PageColumn({ provided, columnIndex, rowIndex }) {
  const { isOpen, onToggle } = useDisclosure();

  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();

  const addSection = (section) => {
    console.log(section);

    let template = undefined;
    //switch section
    switch (section) {
      case "slider":
        template = { type: "ImageCaurosel", data: [] };
        break;
      case "grid-cards":
        template = { type: "GridCards", cards: [] };
        break;
      case "image-card":
        template = {
          type: "ImageAdvert",
          data: {
            imageUrl: "https://via.placeholder.com/300",
            alt: "Alt",
            url: "#",
          },
        };
        break;
      case "image-grid-cards":
        template = {
          type: "ImageGridCard",
          data: {
            layout: {
              base: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr",
              xl: "1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "simple-cards":
        template = {
          type: "SimpleCard",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "info-cards":
        template = {
          type: "InfoCards",
          data: {
            layout: {
              base: "1fr 1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr 1fr 1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            },
            cards: [],
          },
        };
        break;
      case "track":
        template = { type: "TrackButton" };
        break;
      default:
        break;
    }

    if (!template) {
      return;
    }

    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...columns[columnIndex]];
    templates.push(template);
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const removeColumn = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    columns.splice(columnIndex, 1);

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      p="0"
      spacing="0px"
    >
      <HStack
        justify="space-between"
        onClick={onToggle}
        cursor="pointer"
        p="2"
        shadow="sm"
        borderRadius={"md"}
        borderBottomRadius={isOpen ? "0px" : "md"}
      >
        <HStack flexGrow="1">
          {isOpen ? <BiCaretDown /> : <BiCaretRight />}
          <Heading size="xs">Column</Heading>
        </HStack>
        <IconButton
          children={<BiTrash />}
          onClick={removeColumn}
          size="xs"
          colorScheme="red"
        />
        <div {...provided.dragHandleProps}>
          <MdDragIndicator />
        </div>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          p="2"
          py="5"
          color={useColorModeValue("gray.800", "gray.50")}
          bg={useColorModeValue("gray.50", "gray.800")}
          rounded="md"
          borderTopRadius={"0px"}
          borderTopColor={"gray.300"}
          borderTopWidth={"1px"}
        >
          {data &&
            data.data &&
            data.data.widgets[rowIndex].columns[columnIndex].map(
              (template, index) => (
                <Box key={index} shadow="md">
                  {template &&
                    (() => {
                      switch (template.type) {
                        case "ImageCaurosel":
                          return (
                            <EditSlider
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "SimpleCard":
                          return (
                            <SimpleCardsColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "TrackButton":
                          return (
                            <TrackOrderEditor
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "ImageAdvert":
                          return (
                            <ImageAdvertEditor
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "ImageGridCard":
                          return (
                            <ImageGridCardColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "InfoCards":
                          return (
                            <InfoCardsColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        case "GridCards":
                          return (
                            <CardGridColumn
                              columnIndex={columnIndex}
                              rowIndex={rowIndex}
                              templateIndex={index}
                            />
                          );
                        default:
                          return "#FFFFFF";
                      }
                    })()}
                </Box>
              )
            )}

          <Menu>
            <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
              Add Section
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  addSection("grid-cards");
                }}
              >
                Grid Cards
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("slider");
                }}
              >
                Image Slider
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("track");
                }}
              >
                Track Button
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("simple-cards");
                }}
              >
                Simple Cards
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("image-card");
                }}
              >
                Image Card
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("image-grid-cards");
                }}
              >
                Image Grid Cards
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addSection("info-cards");
                }}
              >
                Info Cards
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Collapse>
    </Stack>
  );
}

export default PageColumn;
