import { Button, Stack, useColorModeValue } from "@chakra-ui/react";
import { Heading, Text, Center } from "@chakra-ui/layout";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";
import PageRow from "./PageRow";
import { useSelector, useDispatch } from "react-redux";
import { setPageData } from "../../../../utils/redux/pageDataSlice";

function PageSideBar() {
  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();
  const onDragEnd = async (result) => {
    //console.log(result);
    const { destination, source } = result;
    if (!destination) {
      // alert("Worse");
      return;
    }

    if (destination.index === source.index) {
      // alert("we are equal shuwa here");
      return;
    }

    // alert("Wait");

    const arr = data.data.widgets;
    const newArray = Array.from(arr);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, data.data.widgets[source.index]);

    console.log({ ...data.data, widgets: newArray });

    dispatch(setPageData({ ...data.data, widgets: newArray }));

    return;
  };

  const addRow = () => {
    const date = new Date();
    const id = date.getTime();

    const row = {
      layout: {
        base: "100%",
        md: "1fr 1fr",
        lg: "1fr 1fr",
        xl: "1fr 1fr",
      },
      id: id,
      columns: [],
    };

    // setData({
    //   ...data,
    //   data: { ...data.data, widgets: [...data.data.widgets, row] },
    // });

    dispatch(
      setPageData({ ...data.data, widgets: [...data.data.widgets, row] })
    );
  };

  return (
    <Stack
      bg={useColorModeValue("gray.100", "gray.800")}
      spacing="0"
      p="2"
      w="300px"
      minW="300px"
      h="100%"
      spacing="20px"
      overflowY="scroll"
      color={useColorModeValue("gray.700", "gray.50")}
    >
      <Heading
        size="sm"
        borderLeftStyle={"solid"}
        borderLeftColor={"red.500"}
        borderLeftWidth={"2px"}
        pl="2"
      >
        {data && data.data && data.data.page.title}
      </Heading>

      <DragDropContext onDragEnd={onDragEnd}>
        <Stack spacing="5px">
          {data && data.data && (
            <>
              <Droppable droppableId={"main"}>
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    bg={snapshot.isDraggingOver ? "blue.50" : "transparent"}
                    // py="2"
                    borderLeftColor={"gray.200"}
                    borderStyle={"dashed"}
                    borderLeftWidth={"1px"}
                    spacing="5px"
                    //   style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {data.data.widgets.map((row, index) => (
                      <Draggable
                        key={row.id ? row.id : "row" + index}
                        draggableId={"row" + row.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <PageRow
                            provided={provided}
                            snapshot={snapshot}
                            rowIndex={index}

                            // row={row}
                            // position={index}
                            // setData={setData}
                            // data={data}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>

              <Button
                size="xs"
                p="4"
                onClick={addRow}
                colorScheme="blue"
                // bg="gray.50"
                variant="outline"
                borderRadius={"md"}
                shadow="sm"
              >
                Add Row
              </Button>
            </>
          )}
        </Stack>
      </DragDropContext>
    </Stack>
  );
}

export default PageSideBar;
