import { Checkbox } from "@chakra-ui/checkbox";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Badge, Text } from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";

function ProductListItem({ product, setSelectedProducts, selectedProducts }) {
  const navigate = useNavigate();

  const [images, setImages] = useState();

  useEffect(() => {
    let imgs = JSON.parse(product.images);
    setImages(imgs);
  }, [product]);
  return (
    <Tr
      color={useColorModeValue("gray.700", "gray.50")}
      bg={useColorModeValue("white", "gray.800")}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
      transition={"all 0.35s ease-in-out"}
      cursor="pointer"
      py={0}
    >
      <Td>
        <Checkbox
          isChecked={selectedProducts.includes(product.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedProducts([...selectedProducts, product.id]);
            } else {
              setSelectedProducts(
                selectedProducts.filter((id) => id !== product.id)
              );
            }
          }}
        />
      </Td>
      <Td
        d={{ base: "none", md: "table-cell" }}
        onClick={() => navigate(`/app/shop/product/${product.id}`)}
      >
        {images && images.length > 0 ? (
          <Box w="40px" h="40px">
            <Image
              src={images[0].replace("{size}", "pdpxl")}
              borderRadius="md"
              shadow="md"
            />
          </Box>
        ) : (
          <Box
            w="40px"
            h="40px"
            p="1"
            borderColor="gray.300"
            borderWidth="1px"
            borderRadius="md"
            opacity="0.6"
          >
            <svg viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v15A1.5 1.5 0 0 0 2.5 19h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 17.5 1h-15zm5 3.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM16.499 17H3.497c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01L9 14l3.06-4.781a.5.5 0 0 1 .84.02l4.039 7.011c.18.34-.06.75-.44.75z"></path>
            </svg>
          </Box>
        )}
      </Td>
      <Td
        fontSize="0.9em"
        onClick={() => navigate(`/app/shop/products/${product.id}`)}
      >
        {product.title}
      </Td>
      <Td
        fontSize="0.9em"
        onClick={() => navigate(`/app/shop/products/${product.id}`)}
      >
        {product.availability ? (
          <Badge colorScheme="green">Active</Badge>
        ) : (
          <Badge>Draft</Badge>
        )}
      </Td>

      <Td fontSize="0.9em">
        <Text>
          {product.takealot ? "External" : "Local"}
        </Text>
      </Td>

      <Td fontSize="0.9em">
        <Text>${product.selling_price}</Text>
      </Td>
    </Tr>
  );
}

export default ProductListItem;
