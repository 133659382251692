import {
  Button,
  Grid,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import React from "react";

function InfoCards({ data }) {
  return (
    <Grid templateColumns={data.data.layout} gap="20px" pt="10">
      {data.data &&
        data.data.cards.map((card, index) => (
          <Link key={index} href={"#"}>
            <Stack
              shadow="md"
              bg="white"
              overflow="hidden"
              p="5"
              transition="all 0.3s ease-in-out"
              borderRadius="3px"
              borderWidth="2px"
              borderColor="gray.50"
              align="start"
              _hover={{ cursor: "pointer", shadow: "lg" }}
            >
              <Heading size="sm">{card.heading}</Heading>
              <Text fontSize="0.9em">{card.subHeading}</Text>
              <HStack pt="3" justify="space-between" w="100%">
                <Button
                  colorScheme="red"
                  bg="primary.100"
                  borderRadius="2px"
                  fontSize="0.8em"
                >
                  {card.buttonText}
                </Button>
                <Image maxW="50px" src={card.imageUrl} alt={card.heading} />
              </HStack>
            </Stack>
          </Link>
        ))}
    </Grid>
  );
}

export default InfoCards;
