import {
  FormControl,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";
import ImageSlide from "./ImageSlide";

function EditSlider({ columnIndex, rowIndex, templateIndex }) {
  const data = useSelector((state) => state.pagedata.value);

  const dispatch = useDispatch();

  const addNewImage = () => {
    let images = [
      ...data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data,
    ];
    images.push({ imageUrl: "https://via.placeholder.com/150", url: "#" });

    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];

    //console.log(images);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: images,
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  const removeSection = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    templates.splice(templateIndex, 1);

    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <Stack
      p="2"
      borderColor={"gray.100"}
      borderWidth={"1px"}
      borderStyle={"solid"}
    >
      <HStack align="center">
        <Heading
          size="xs"
          borderBottomWidth={"1px"}
          borderBottomColor={"gray.100"}
          flexGrow="1"
        >
          Image Slider
        </Heading>
        <IconButton size="xs" children={<BiPlus />} onClick={addNewImage} />
        <IconButton
          size="xs"
          children={<BiTrash />}
          onClick={() => {
            removeSection();
          }}
        />
      </HStack>

      {data &&
        data.data &&
        data.data.widgets[rowIndex].columns[columnIndex][templateIndex].data
          .length > 0 &&
        data.data.widgets[rowIndex].columns[columnIndex][
          templateIndex
        ].data.map((card, index) => (
          <ImageSlide
            imageIndex={index}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            templateIndex={templateIndex}
            // card={card}
            // position={index}
            // temp={temp}
            // setTemp={setTemp}
            // key={index}
          />
        ))}
      {/* <GridCard /> */}
    </Stack>
  );
}

export default EditSlider;
