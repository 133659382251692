import { Grid, Image, Link, Stack } from "@chakra-ui/react";

import React from "react";

function ImageGridCard({ data }) {
  if (!data) {
    return <></>;
  }
  return (
    <Grid templateColumns={data.data.layout} gap="20px">
      {data.data &&
        data.data.cards.map((card, index) => (
          <Link key={index} href={"#"}>
            <Stack
              shadow="md"
              bg="white"
              overflow="hidden"
              p="0"
              transition="all 0.3s ease-in-out"
              borderRadius="3px"
              borderWidth="2px"
              borderColor="gray.50"
              align="center"
              _hover={{ cursor: "pointer", shadow: "lg" }}
            >
              <Image src={card.imageUrl} alt={card.alt} w="100%" h="auto" />
            </Stack>
          </Link>
        ))}
    </Grid>
  );
}

export default ImageGridCard;
