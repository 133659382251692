import { Container, Image, Link } from "@chakra-ui/react";
import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

function ImageCaurosel({ data }) {
  return (
    <Splide>
      {data &&
        data.data &&
        data.data.map((image, index) => (
          <SplideSlide key={index}>
            <Link to="#">
              <Image
                borderRadius="10px"
                shadow="md"
                w="100%"
                alt="Caurosel"
                src={image.imageUrl}
              />
            </Link>
          </SplideSlide>
        ))}
    </Splide>
  );
}

export default ImageCaurosel;
