import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";
import {
  Stack,
  Heading,
  HStack,
  Text,
  Center,
  Container,
  Box,
} from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Tbody, Td, Thead, Tr } from "@chakra-ui/table";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import axiosInstance from "../../../utils/axios";

function EditFees() {
  const [fees, setFees] = useState();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [zar, setZar] = useState(2500);
  const [surcharge] = useState(100);

  const toast = useToast();
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/store/fees")
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.zar_rate) {
          setFees(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const saveFees = () => {
    setUploading(true);
    axiosInstance
      .put("/store/fees", fees)
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data?.zar_rate) {
          setFees(data);
          toast({ status: "success", description: "Fees updated" });
        }
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  return (
    <Center>
      <Container maxW="1100px" width="100%">
        <Stack p="5">
          {/* The Heading */}
          <HStack justify="space-between">
            <Heading size="md" color={useColorModeValue("gray.700", "gray.50")}>
              Fees
            </Heading>
          </HStack>
          <Stack borderRadius="md" overFlow="hidden">
            <HStack spacing="30px" align="start">
              <Stack
                flexGrow="1"
                maxW="500px"
                minW="400px"
                spacing="20px"
                pt="5"
                color={useColorModeValue("gray.700", "gray.50")}
              >
                <FormControl>
                  <FormLabel>USD/ZAR</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.zar_rate}
                      onChange={(e) => {
                        setFees({ ...fees, zar_rate: e.target.value });
                      }}
                      placeholder="USD/ZAR Rate"
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>SA - ZIM Freight</FormLabel>
                  <InputGroup>
                    <Input
                      value={fees && fees.sa_zim}
                      type="number"
                      placeholder="SA - ZIM Freight"
                      onChange={(e) => {
                        setFees({ ...fees, sa_zim: e.target.value });
                      }}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Sorting</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.sorting}
                      placeholder="Sorting"
                      onChange={(e) => {
                        setFees({ ...fees, sorting: e.target.value });
                      }}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Ads</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.ads}
                      placeholder="Ads Cost"
                      onChange={(e) => {
                        setFees({ ...fees, ads: e.target.value });
                      }}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Transport</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.transport}
                      placeholder="Transport"
                      onChange={(e) => {
                        setFees({ ...fees, transport: e.target.value });
                      }}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>MFI</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.mfi}
                      placeholder="MFI"
                      onChange={(e) => {
                        setFees({ ...fees, mfi: e.target.value });
                      }}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Wages & Salaries</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      value={fees && fees.wages}
                      onChange={(e) => {
                        setFees({ ...fees, wages: e.target.value });
                      }}
                      placeholder="Wages & Salaries"
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
              </Stack>
              <Stack
                shadow="md"
                minW="300px"
                bg={useColorModeValue("white", "gray.900")}
                borderRadius="md"
                color={useColorModeValue("gray.700", "gray.50")}
              >
                <Text
                  p={5}
                  bg={useColorModeValue("gray.700", "gray.600")}
                  color={useColorModeValue("gray.50", "gray.50")}
                >
                  Selling Price Calculator
                </Text>

                {!fees ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <Table variant="striped" size="sm">
                    <Tbody>
                      <Tr>
                        <Td>
                          <Text my="2" fontWeight="bold">
                            ZAR Price
                          </Text>
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td isNumeric>
                          <Input
                            value={zar}
                            type={"number"}
                            inline
                            bg={"gray.700"}
                            color={"gray.50"}
                            borderColor="blue.50"
                            borderWidth="2px"
                            shadow="md"
                            placeholder="ZAR Price"
                            onChange={(e) => {
                              setZar(parseFloat(e.target.value));
                            }}
                          />
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>ZAR Surcharge</Td>
                        <Td>{surcharge}</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>Total ZAR</Td>
                        <Td></Td>
                        <Td>{(zar ? parseFloat(zar) : 0) + surcharge}</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight="bold">
                            ZAR/USD @{fees.zar_rate * 100} / 1
                          </Text>
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td isNumeric>
                          <Text fontWeight="bold">
                            $
                            {(
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed()}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>SA-ZIM @{(fees.sa_zim * 100).toFixed(2)}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.sa_zim
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>Sorting @{(fees.sorting * 100).toFixed(2)}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.sorting
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>Ads @{fees.ads * 100}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.ads
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>Transport @{(fees.transport * 100).toFixed(2)}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.transport
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>MFI @{(fees.mfi * 100).toFixed(2)}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.mfi
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td>Wages @{(fees.wages * 100).toFixed(2)}%</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          $
                          {(
                            (
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                              (fees.zar_rate * 100)
                            ).toFixed() * fees.wages
                          ).toFixed(2)}
                        </Td>
                        <Td></Td>
                      </Tr>

                      <Tr
                      // style={{ background: "#3e3e3e !important" }}
                      // color="gray.50"
                      >
                        <Td>
                          <Heading size="xs" my="2">
                            Sellig Price
                          </Heading>
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td isNumeric>
                          <Heading size="xs">
                            $
                            {(
                              ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100) +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.sa_zim +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.sorting +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.ads +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.transport +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.mfi +
                              (
                                ((zar ? parseFloat(zar) : 0) + surcharge) /
                                (fees.zar_rate * 100)
                              ).toFixed() *
                                fees.wages
                            ).toFixed(2)}
                          </Heading>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                )}
              </Stack>
            </HStack>
            <Box>
              {fees && (
                <Button
                  colorScheme="green"
                  isLoading={uploading}
                  onClick={saveFees}
                >
                  Save
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Center>
  );
}

export default EditFees;
