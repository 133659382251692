import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { BiCaretDown, BiCaretRight, BiTrash } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import React, { useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PageColumn from "./PageColumn";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPageData } from "../../../../utils/redux/pageDataSlice";

function PageRow({ provided, snapshot, rowIndex }) {
  const data = useSelector((state) => state.pagedata.value);
  const dispatch = useDispatch();
  const { isOpen, onToggle } = useDisclosure();

  const [winReady, setwinReady] = useState(false);
  useEffect(() => {
    setwinReady(true);
  }, []);

  // console.log(position);
  const onDragEnd = async (result) => {
    console.log(result);
    return;
  };

  const removeRow = () => {
    let widgets = [...data.data.widgets];
    widgets.splice(rowIndex, 1);
    dispatch(setPageData({ ...data.data, widgets: widgets }));
  };

  const addColumn = () => {
    let columns = [...data.data.widgets[rowIndex].columns];
    columns.push([]);

    let rows = [...data.data.widgets];

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    dispatch(setPageData({ ...data.data, widgets: rows }));
  };


  


  
  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      p="0"
      spacing="0px"
      bg={useColorModeValue("gray.50", "gray.900")}
      borderRadius={"md"}
    >
      {winReady && (
        <>
          <HStack
            justify="space-between"
            onClick={onToggle}
            cursor="pointer"
            p="2"
            shadow="sm"
            borderRadius={"md"}
            borderBottomRadius={isOpen ? "0px" : "md"}
          >
            <HStack flexGrow="1">
              {isOpen ? <BiCaretDown /> : <BiCaretRight />}
              <Heading size="xs">Row</Heading>
            </HStack>
            <IconButton
              children={<BiTrash />}
              onClick={removeRow}
              size="xs"
              colorScheme="red"
            />
            <div {...provided.dragHandleProps}>
              <MdDragIndicator />
            </div>
          </HStack>
          <Collapse in={isOpen} animateOpacity>
            <Stack
              p="2"
              py="5"
              rounded="md"
              borderTopRadius={"0px"}
              borderTopColor={"gray.300"}
              borderTopWidth={"1px"}
              shadow="sm"
            >
              {data.data && !data.loading && data.data.widgets[rowIndex] && (
                <Grid
                  templateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }}
                  gap="10px"
                >
                  <FormControl>
                    <FormLabel fontSize="xs">Mobile</FormLabel>
                    <Input
                      fontSize={"xs"}
                      value={data.data.widgets[rowIndex].layout.base}
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        rows[rowIndex] = {...rows[rowIndex], layout: {...rows[rowIndex].layout, base: e.target.value}};
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                        // setLayout({ ...layout, base: e.target.value });
                      }}
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="xs">Tablet View</FormLabel>
                    <Input
                      fontSize={"xs"}
                      value={data.data.widgets[rowIndex].layout.md}
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        rows[rowIndex] = {...rows[rowIndex], layout: {...rows[rowIndex].layout, md: e.target.value}};
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                        // setLayout({ ...layout, base: e.target.value });
                      }}
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="xs">Base Laptop</FormLabel>
                    <Input
                      fontSize={"xs"}
                      value={data.data.widgets[rowIndex].layout.lg}
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        rows[rowIndex] = {...rows[rowIndex], layout: {...rows[rowIndex].layout, lg: e.target.value}};
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                        // setLayout({ ...layout, base: e.target.value });
                      }}
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="xs">Large Screens</FormLabel>
                    <Input
                      fontSize={"xs"}
                      value={data.data.widgets[rowIndex].layout.xl}
                      onChange={(e) => {
                        let rows = [...data.data.widgets];
                        rows[rowIndex] = {...rows[rowIndex], layout: {...rows[rowIndex].layout, xl: e.target.value}};
                        dispatch(setPageData({ ...data.data, widgets: rows }));
                        // setLayout({ ...layout, base: e.target.value });
                      }}
                    ></Input>
                  </FormControl>
                </Grid>
              )}

              <DragDropContext onDragEnd={onDragEnd}>
                <>
                  <Droppable droppableId={"row"}>
                    {(provided, snapshot) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        bg={snapshot.isDraggingOver ? "red.50" : "transparent"}
                        // py="2"
                        borderLeftColor={"gray.200"}
                        borderStyle={"dashed"}
                        borderLeftWidth={"1px"}
                        spacing="5px"
                        //   style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {data.data.widgets[rowIndex].columns.map(
                          (column, index) => (
                            <Draggable
                              key={"row" + index}
                              draggableId={"row" + index}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <PageColumn
                                  columnIndex={index}
                                  provided={provided}
                                  rowIndex={rowIndex}
                                />
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>

                  <Button
                    size="xs"
                    p="4"
                    onClick={addColumn}
                    // bg="gray.50"
                    variant="outline"
                    borderRadius={"md"}
                    shadow="sm"
                  >
                    Add Column
                  </Button>
                </>
              </DragDropContext>
            </Stack>
          </Collapse>
        </>
      )}
    </Stack>
  );
}

export default PageRow;
