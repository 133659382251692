import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import React from "react";
import { Link } from "react-router-dom";

function GridCards({ data }) {
  //console.log(data);
  return (
    <Stack>
      <Heading size="sm" color="red.500">
        {data.title}
      </Heading>
      <Grid
        templateColumns={data.layout}
        gap="20px"
        display={{ base: "grid", md: "none" }}
      >
        {data &&
          data.cards.map((card, index) => (
            <Stack
              shadow="md"
              bg="white"
              overflow="hidden"
              p="0"
              transition="all 0.3s ease-in-out"
              borderRadius="3px"
              borderWidth="2px"
              borderColor="gray.50"
              align="center"
              _hover={{ cursor: "pointer", shadow: "lg" }}
              pb="3"
              key={index}
            >
              <Image
                src={card.imageUrl}
                alt={card.label}
                w="100%"
                h="auto"
                p="2"
              />
              {card.label && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: card.label.replace(
                      /(<? *script)/gi,
                      "illegalscript"
                    ),
                  }}
                ></Text>
              )}

              {card.buttonText && (
                <Button mb="3" size="sm" colorScheme="green" bg="#4da60b">
                  {card.buttonText}
                </Button>
              )}
            </Stack>
          ))}
      </Grid>

      <Box display={{ base: "none", md: "block" }}>
        <Splide
          options={{
            perPage: 5,
            gap: "20px",
            lazyLoad: "nearby",
            rewind: true,
            pagination: false,
            classes: { arrows: "nav-arrow" },
            breakpoints: {
              1200: {
                perPage: 4,
              },
              975: {
                perPage: 3,
              },
              640: {
                perPage: 2,
              },
            },
          }}
        >
          {data &&
            data.cards.map((card, index) => (
              <SplideSlide key={index}>
                <Link to="#">
                  <Stack
                    shadow="md"
                    bg="white"
                    overflow="hidden"
                    p="0"
                    transition="all 0.3s ease-in-out"
                    borderRadius="3px"
                    borderWidth="2px"
                    borderColor="gray.50"
                    align="center"
                    _hover={{ cursor: "pointer", shadow: "lg" }}
                    pb="3"
                  >
                    <Image
                      src={card.imageUrl}
                      alt={card.label}
                      w="100%"
                      h="auto"
                      p="2"
                    />
                    {card.label && (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: card.label.replace(
                            /(<? *script)/gi,
                            "illegalscript"
                          ),
                        }}
                      ></Text>
                    )}

                    {card.buttonText && (
                      <Button mb="3" size="sm" colorScheme="green" bg="#4da60b">
                        {card.buttonText}
                      </Button>
                    )}
                  </Stack>
                </Link>
              </SplideSlide>
            ))}
        </Splide>
      </Box>
    </Stack>
  );
}

export default GridCards;
