import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";

function SimpleCard({ rowIndex, columnIndex, templateIndex, cardIndex }) {
  // console.log(card);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useSelector((state) => state.pagedata.value);

  const dispatch = useDispatch();

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];
            let images = [
              ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
            ];
            images[cardIndex] = {
              ...images[cardIndex],
              imageUrl: result.info.url,
            };
            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              data: {
                ...rows[rowIndex].columns[columnIndex][templateIndex].data,
                cards: images,
              },
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }
        }
      }
    );
    myWidget.open();
  };

  const removeCard = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    let cards = [
      ...rows[rowIndex].columns[columnIndex][templateIndex].data.cards,
    ];
    cards.splice(cardIndex, 1);
    console.log(cards);
    //columns.splice(columnIndex, 1);
    templates[templateIndex] = {
      ...templates[templateIndex],
      data: {
        ...rows[rowIndex].columns[columnIndex][templateIndex].data,
        cards: cards,
      },
    };
    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };

    console.log(rows);
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };

  return (
    <>
      {data && (
        <HStack>
          <Image
            w="30px"
            h="30px"
            objectFit={"cover"}
            src={
              data.data &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.cards[cardIndex] &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.cards[cardIndex].imageUrl
            }
          />
          <Text flexGrow="1" fontSize={"xs"}>
            {data.data &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.cards[cardIndex] &&
              data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                .data.cards[cardIndex].label}
          </Text>
          <ButtonGroup>
            <IconButton size="xs" children={<BiPencil />} onClick={onOpen} />
            <IconButton size="xs" children={<BiTrash />} onClick={removeCard} />
          </ButtonGroup>
        </HStack>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Grid Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="2">
            <Stack spacing="20px" pb="20px">
              <Box bg="gray.900" position="relative">
                <Image
                  src={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex].imageUrl
                  }
                  w="100%"
                  opacity="0.5"
                />
                <IconButton
                  children={<BiPencil />}
                  position="absolute"
                  top="48%"
                  left="48%"
                  onClick={showWidget}
                />
              </Box>

              <FormControl>
                <FormLabel fontSize={"xs"}>Title</FormLabel>
                <Input
                  placeholder="Title"
                  value={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex].label
                  }
                  onChange={(e) => {
                    // setLoCard({ ...locard, label: e.target.value });
                    let rows = [...data.data.widgets];
                    let columns = [...rows[rowIndex].columns];
                    let templates = [...rows[rowIndex].columns[columnIndex]];
                    let cards = [
                      ...rows[rowIndex].columns[columnIndex][templateIndex].data
                        .cards,
                    ];
                    cards[cardIndex] = {
                      ...cards[cardIndex],
                      label: e.target.value,
                    };
                    //console.log(images);
                    //columns.splice(columnIndex, 1);
                    templates[templateIndex] = {
                      ...templates[templateIndex],
                      data: {
                        ...rows[rowIndex].columns[columnIndex][templateIndex]
                          .data,
                        cards: cards,
                      },
                    };
                    columns[columnIndex] = templates;

                    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                    dispatch(setPageData({ ...data.data, widgets: rows }));
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"xs"}>Url</FormLabel>
                <Input
                  placeholder="Url"
                  value={
                    data.data &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex] &&
                    data.data.widgets[rowIndex].columns[columnIndex][
                      templateIndex
                    ].data.cards[cardIndex].url
                  }
                  onChange={(e) => {
                    let rows = [...data.data.widgets];
                    let columns = [...rows[rowIndex].columns];
                    let templates = [...rows[rowIndex].columns[columnIndex]];
                    let cards = [
                      ...rows[rowIndex].columns[columnIndex][templateIndex].data
                        .cards,
                    ];
                    cards[cardIndex] = {
                      ...cards[cardIndex],
                      url: e.target.value,
                    };
                    //console.log(images);
                    //columns.splice(columnIndex, 1);
                    templates[templateIndex] = {
                      ...templates[templateIndex],
                      data: {
                        ...rows[rowIndex].columns[columnIndex][templateIndex]
                          .data,
                        cards: cards,
                      },
                    };
                    columns[columnIndex] = templates;

                    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                    dispatch(setPageData({ ...data.data, widgets: rows }));
                  }}
                />
              </FormControl>

              <Box>
                <Button size="sm" onClick={onClose}>
                  Save
                </Button>
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SimpleCard;
