import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setPageData } from "../../../../../utils/redux/pageDataSlice";

function ImageAdvertEditor({ columnIndex, rowIndex, templateIndex }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useSelector((state) => state.pagedata.value);

  const dispatch = useDispatch();

  const showWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "huvuru",
        uploadPreset: "ponhwf4e",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          if (result.info) {
            let rows = [...data.data.widgets];
            let columns = [...rows[rowIndex].columns];
            let templates = [...rows[rowIndex].columns[columnIndex]];
            let advert = {
              ...rows[rowIndex].columns[columnIndex][templateIndex].data,
              imageUrl: result.info.url,
            };

            //console.log(images);
            //columns.splice(columnIndex, 1);
            templates[templateIndex] = {
              ...templates[templateIndex],
              data: { ...advert },
            };
            columns[columnIndex] = templates;

            rows[rowIndex] = { ...rows[rowIndex], columns: columns };
            dispatch(setPageData({ ...data.data, widgets: rows }));
          }
        }
      }
    );
    myWidget.open();
  };

  const removeSection = () => {
    let rows = [...data.data.widgets];
    let columns = [...rows[rowIndex].columns];
    let templates = [...rows[rowIndex].columns[columnIndex]];
    templates.splice(templateIndex, 1);

    columns[columnIndex] = templates;

    rows[rowIndex] = { ...rows[rowIndex], columns: columns };
    dispatch(setPageData({ ...data.data, widgets: rows }));
  };
  return (
    <Stack p="2">
      <Heading size="xs">Image Card</Heading>

      <>
        {data && (
          <Stack justify="space-between">
            <Image
              maxW="100%"
              h="auto"
              objectFit={"cover"}
              src={
                data.data &&
                data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                  .data &&
                data.data.widgets[rowIndex].columns[columnIndex][templateIndex]
                  .data.imageUrl
              }
            />

            <ButtonGroup>
              <IconButton size="xs" children={<BiPencil />} onClick={onOpen} />
              <IconButton
                size="xs"
                children={<BiTrash />}
                onClick={removeSection}
              />
            </ButtonGroup>
          </Stack>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Image Slide</ModalHeader>
            <ModalCloseButton />
            <ModalBody p="2">
              <Stack spacing="20px" pb="20px">
                <Box bg="gray.900" position="relative">
                  <Image
                    src={
                      data.data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data.imageUrl
                    }
                    w="100%"
                    opacity="0.5"
                  />
                  <IconButton
                    children={<BiPencil />}
                    position="absolute"
                    top="48%"
                    left="48%"
                    onClick={showWidget}
                  />
                </Box>

                <FormControl>
                  <FormLabel fontSize={"xs"}>Url</FormLabel>
                  <Input
                    placeholder="Url"
                    value={
                      data.data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data.url
                    }
                    onChange={(e) => {
                      let rows = [...data.data.widgets];
                      let columns = [...rows[rowIndex].columns];
                      let templates = [...rows[rowIndex].columns[columnIndex]];
                      let image = {
                        ...rows[rowIndex].columns[columnIndex][templateIndex]
                          .data,
                        url: e.target.value,
                      };
                      //   images[imageIndex] = {
                      //     ...images[imageIndex],
                      //     url: e.target.value,
                      //   };
                      //console.log(images);
                      //columns.splice(columnIndex, 1);
                      templates[templateIndex] = {
                        ...templates[templateIndex],
                        data: image,
                      };
                      columns[columnIndex] = templates;

                      rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                      dispatch(setPageData({ ...data.data, widgets: rows }));
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"xs"}>Alt</FormLabel>
                  <Input
                    placeholder="Alt"
                    value={
                      data.data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data &&
                      data.data.widgets[rowIndex].columns[columnIndex][
                        templateIndex
                      ].data.alt
                    }
                    onChange={(e) => {
                      let rows = [...data.data.widgets];
                      let columns = [...rows[rowIndex].columns];
                      let templates = [...rows[rowIndex].columns[columnIndex]];
                      let image = {
                        ...rows[rowIndex].columns[columnIndex][templateIndex]
                          .data,
                        alt: e.target.value,
                      };
                      //   images[imageIndex] = {
                      //     ...images[imageIndex],
                      //     url: e.target.value,
                      //   };
                      //console.log(images);
                      //columns.splice(columnIndex, 1);
                      templates[templateIndex] = {
                        ...templates[templateIndex],
                        data: image,
                      };
                      columns[columnIndex] = templates;

                      rows[rowIndex] = { ...rows[rowIndex], columns: columns };
                      dispatch(setPageData({ ...data.data, widgets: rows }));
                    }}
                  />
                </FormControl>

                <Box>
                  <Button size="sm" onClick={onClose}>
                    Save
                  </Button>
                </Box>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Stack>
  );
}

export default ImageAdvertEditor;
